*{
  margin: 0;
  padding: 0;
}
.MagnifierViewPDFIFrame{
  margin-top: -20px;
  /* background-color: grey; */
}
.MagnifierViewPDFIFrame span {
  font-size: 20px;
  color: white;
  cursor: pointer;
  float: right;
}