.menuBox{
    width: 92%;
    background-color: black;
    margin: 0 auto;
    margin-top: 15px;
    height: 100px;
    border-radius: 15px;
    color: white;
    padding-top: 27px;
    padding-left: 14px;
    padding-right: 18px;
    border: 1px solid rgb(85, 85, 85);
}
.menuBox h2{
    font-weight: bold;
    color: #1DCCFF;
}
.menuBox p{
    font-size: 15px;
    margin-top: -4px;
}