.profileDataContainer {
    color: black;
    background-color: #1A3330;
    width: 92%;
    border-radius: 12px;
    margin: 0 auto;
    padding-top: 15px;
    padding-left: 9px;
    padding-right: 9px;
    padding-bottom: 13px;
}

.profileImgSection {
    border: 3px solid white;
    max-width: 170px;
    height: 129px;
    position: relative;
    left: 10px;
    margin-left: -10px;
    border-radius: 12px;
    /* border: 3px solid white;
    background-color: black;
    max-width: 120px;
    margin-right: 20px;
    position: relative;
    left: 15px; */
}
.profileImgSection img {
    width: 100%;
    height: 123px;
    border-radius: 9px;
    /* border-radius: 12px; */

}
.myProfileMainLocationBox {
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 4px 5px;
    border-radius: 8px;
    margin-bottom: 7px;
}

.myProfileMainLocationBox span {
    font-size: 19px;
    font-weight: bold;
    margin-left: -30px;
}

.profileImgAddSec {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.myProfileTrustScoreTxt {
    margin-top: 21px;
    color: #1dccff;
    font-size: 18px;
    text-align: center;
}

.myProfileFeildsContainer {
    width: 92%;
    margin: 0 auto;
}

.myProfileFeild {
    background-color: #1A3330;
    border: 3px solid #1dccff;
    padding: 4px 10px;
    padding-right: 10px;
    color: #ffffff;
}

.myProfileFeildsDiscription {
    color: white;
    margin-top: 12px;
    font-size: 19px;
}

.myProfileFurtherServices {
    height: 110px;
    border-radius: 0px;
    padding-top: 8px;
    position:absolute ;
    width: 86.5%;
}

.myProfileFurtherServices h4 {
    font-size: 21px;
    margin-left: 20px;
}

#trustScoreInput {
    margin-left: 0px;
}

.myProfileLocation {
    background-color: transparent;
    /* background-color: red; */
    border: none;
    /* width: 68%; */
    width: 87%;
    float: right;
    margin-right: -16px;
    outline: none;
}